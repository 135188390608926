.chartDiv {
    text-align: center;
  }

  .chartDivSmall {
    text-align: center;
    max-width: 50%;
  }

  .otherDiv {  
    max-width: 90%;
  }